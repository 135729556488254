function isDoNotTrackEnabled() {
	if (typeof window === "undefined") return false
	// Safari 7.1.3+ uses window.doNotTrack rather than navigator.doNotTrack.
	// IE9+ Implemented with the vendor prefix: ms
	// For IE11 and subsequent versions, use window.doNotTrack
	const { doNotTrack, navigator } = window
	const dnt = doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack
	return (
		dnt === true ||
		dnt === 1 ||
		dnt === "yes" ||
		(typeof dnt === "string" && dnt.charAt(0) === "1")
	)
}

const settings = {
	cookieNameFirstTouchPrefix: "ft_",
	cookieNamePrefix: "lt_",
	utmParams: [
		"utm_source",
		"utm_medium",
		"utm_campaign",
		"utm_term",
		"utm_content",
	],
	cookieExpiryDays: 365,
	isFirstTouch: null,
}

/**
 * Determines the highest level domain at which a cookie can be set for the current page.
 * It tries to set a test cookie at each level of the domain, starting from the top-level domain and moving up.
 * If the cookie is successfully set, it deletes the test cookie and returns the current level of the domain.
 * If the cookie cannot be set at any level, it returns the full hostname of the current page.
 *
 * @returns {string} The highest level domain at which a cookie can be set.
 */
function topDomain() {
	// Define a test cookie
	const topLevelCookie = "top_level_domain=cookie"

	// Get the current page's hostname and split it into parts
	const hostname = window.document.location.hostname.split(".")

	// Start from the last part of the hostname (the top-level domain like .com, .org, etc.) and go backwards
	for (let i = hostname.length - 1; i >= 0; i -= 1) {
		// Construct the current level of the domain
		const h = hostname.slice(i).join(".")

		// Try to set the test cookie at the current level of the domain
		window.document.cookie = `${topLevelCookie};domain=.${h};`

		// Check if the cookie was successfully set
		if (window.document.cookie.indexOf(topLevelCookie) > -1) {
			// If the cookie was successfully set, delete the test cookie
			window.document.cookie = `${
				topLevelCookie.split("=")[0]
			}=;domain=.${h};expires=Thu, 01 Jan 1970 00:00:01 GMT;`

			// Return the current level of the domain
			return h
		}
	}

	// If the cookie could not be set at any level, return the full hostname of the current page
	return window.document.location.hostname
}

function createCookie(name, value, days) {
	let expires = ""
	if (days) {
		const date = new Date()
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
		expires = `; expires=${date.toGMTString()}`
	}

	let domain = topDomain()

	// Add the dot before the top level domain unless this is localhost dev
	if (domain !== "localhost") domain = `.${domain}`

	const c = `${name}=${value}${expires}; domain=${domain}; path=/`
	// console.log(`utm_cookie set ${c}`)
	window.document.cookie = c
}

function isFirstTouch() {
	if (settings.isFirstTouch != null) return settings.isFirstTouch

	const f =
		window.document.cookie.indexOf(settings.cookieNameFirstTouchPrefix) === -1
	settings.isFirstTouch = f
	return f
}

function writeCookieOnce(name, value) {
	if (isFirstTouch()) {
		createCookie(
			settings.cookieNameFirstTouchPrefix + name,
			value,
			settings.cookieExpiryDays
		)
	}
	createCookie(
		settings.cookieNamePrefix + name,
		value,
		settings.cookieExpiryDays
	)
}

function readCookie(name) {
	const nameEQ = `${name}=`
	const ca = window.document.cookie.split(";")
	for (let i = 0; i < ca.length; i += 1) {
		let c = ca[i]
		while (c.charAt(0) === " ") c = c.substring(1, c.length)
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
	}
	return undefined
}

function getParameterByName(name) {
	const paramName = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]")
	const regexS = `[\\?&]${paramName}=([^&#]*)`
	const regex = new RegExp(regexS)
	const results = regex.exec(window.location.search)
	if (results == null) {
		return ""
	}

	return decodeURIComponent(results[1].replace(/\+/g, " "))
}

function utmPresentInUrl() {
	for (let i = 0; i < settings.utmParams.length; i += 1) {
		const param = settings.utmParams[i]
		const value = getParameterByName(param)
		if (value !== "" && value !== undefined) {
			return true
		}
	}
	return false
}

function writeUtmCookieFromParams() {
	if (utmPresentInUrl()) {
		for (let i = 0; i < settings.utmParams.length; i += 1) {
			const param = settings.utmParams[i]
			const value = getParameterByName(param)
			writeCookieOnce(param, value)
		}
	}
}

function writeReferrer() {
	const value = window.document.referrer
	const key = "referrer"
	if (
		value &&
		value !== "" &&
		value !== undefined &&
		value.indexOf(window.document.location.host) === -1
	) {
		writeCookieOnce(key, value)
	} else {
		writeCookieOnce(key, "direct")
	}
}

function isNewSession() {
	const c = "__utm_tracking_session"
	const r = readCookie(c) === undefined
	createCookie(c, true, 1 / 48) // session window is half-hour
	return r
}

function storeParamsInCookies() {
	if (isDoNotTrackEnabled()) {
		console.log("utm_cookie Do not track is on")
		return
	}

	if (isNewSession()) {
		console.log("utm_cookie new session detected, writing utm param cookies")
		writeUtmCookieFromParams()
		writeReferrer()
	} else {
		console.log("utm_cookie No new session, no-op")
	}
}

storeParamsInCookies()
